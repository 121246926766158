import React from "react"
import Link from "gatsby-link"

const successfullySubmitted = () => {
  return (
    <div className="bg-black h-screen">
      <h1
        className="lg:text-80 md:text-70 sm:text-60 text-25 absolute text-yellow headline w-full text-center"
        style={{ fontFamily: "Knockout", top: "45%" }}
      >
        Successfully Submitted
      </h1>
      <div className="h-32 flex justify-end items-center">
        <Link to="/job" className="outline-none">
          <div className="mr-20 outline-none text-yellow">Done</div>
        </Link>
      </div>
      <div className="flex justify-center items-center"></div>
    </div>
  )
}

export default successfullySubmitted
